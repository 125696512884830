.background{
background:#595959;
min-height: 100%;
position:relative;
}

/* .container {
  position: relative;
} */

.links {
  position: absolute;
  top: 8.4%;
  left: 27%
}

.instaimg{
width: 15%;
padding-left: 5%;
}

.fbimg{
width:40%;
}

.instaimg:hover, .fbimg:hover{
  transform: scale(1.2);
  transition-delay:0.2s ease-in-out;
}

@media (max-width: 440px) {
  .links {
    top: 8.5%;
    left:22%;
  }
  .instaimg{
    padding-left:13%;
  }
  .fbimg{
    width: 44%;
  }
}

@media (max-width: 220px) {
  .links {
    top: 9%;
  }
}