form {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    max-width: 800px;
  }

  input,
  button {
    width: 100%;
    box-sizing: border-box;
    margin: 1% 0;
    height: 14vh;
    padding: 1%;
    font-size: max(3vw, 16px);
  }
  
  button {
    background-color: #F77C1F;
    color: white;
    border: none;
    font-size: max(2vw, 16px);
    font-family: sans-serif;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgb(48, 47, 47);
    transition: background-color 0.3s ease; /* Add a smooth hover effect */
  }
  
  button:hover {
    background-color: #cb3d01;
    cursor: pointer;
  }

  @media (max-width: 900px) {
    form {
        max-width: 400px;
      }
    
      input,
      button {
        height: 7vh;
        font-size: 2.5vw;
        text-align: center;
      }
      
      button {
        font-size: max(2vw, 14px);
    }
  }


  @media (max-width: 440px) {
    form {
        top: 30%;
}
input, button{
    height:4vh;
}
    button {
      font-size: 2vh;
  }
}

@media (max-width: 279px) {
  button{
    font-size: 1.5vh;
  }
  }

  @media (max-width: 220px) {
    button{
      font-size: 1.3vh;
    }
    }