.page2, .page3, .page4, .page5, .page6, 
.page7, .page8, .page9, .page10{
    max-width: 70%;
    padding-top: 3vh;
    display: block;
    margin: 0 auto;
  }

  .page10, .page4{
    padding-bottom: 3vh;
  }

  @media screen and (max-width:440px) {
    .page2, .page3, .page4, .page5, .page6, 
.page7, .page8, .page9, .page10{
      max-width: 95%;
    }
    }