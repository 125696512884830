.indexBackground {
  background: #595959;
  /* background-repeat: no-repeat;
  background-size: cover; */
  min-height: 100%;
  /* background-attachment: fixed; */
};

.indexContainer{
  padding: 20px;
  overflow-y: auto;
  max-height: calc(100vh - 20vh);
}

.page1{
  max-width: 70%;
  display:block;
  margin: 0 auto;
  padding-top:2%;
}

.index{
  position:relative;
/* width:100%;
max-width: 100%; */
}

@media screen and (max-width:440px) {
.page1{
  max-width: 95%;
}
}